Element.prototype.wrap = function (wrapperHtml) {
  const tmp = document.createElement("div");
  tmp.innerHTML = wrapperHtml;

  let target = tmp.firstChild;
  while (target.firstChild) target = target.firstChild;

  this.parentNode.insertBefore(tmp.firstChild, this);
  target.appendChild(this);
}

const addPasswordToggle = (el) => {
  el.wrap("<div class='ctrl-wrapper'></div>");

  const toggle = document.createElement("div");
  toggle.classList.add("toggle-pass");
  toggle.innerHTML = "<i class='fas fa-eye'></i>";

  el.after(toggle);
  toggle.firstChild.addEventListener("click", e => {
    e.target.classList.toggle("fa-eye");
    e.target.classList.toggle("fa-eye-slash");
    el.setAttribute(
      "type",
      el.getAttribute("type") === "password" ? "text" : "password"
    );
  });
}

const wrapNumberInput = (el) => {
  el.wrap("<div class='ctrl-wrapper'></div>")

  const stepper = document.createElement("div");
  stepper.classList.add("input-number-step-btns");
  stepper.innerHTML =
      "<div class='input-number-step-up'><i class='fas fa-caret-up'></i></div>" +
      "<div class='input-number-step-down'><i class='fas fa-caret-down'></i></div>";
  el.after(stepper);

  const max = el.getAttribute("max");
  const min = el.getAttribute("min");

  const parseCurrent = () => {
    const valStr = el.value?.trim();
    if (valStr === "") {
      el.value = min ? min : 0;
      return "";
    }
    return parseInt(valStr);
  }

  const wrapper = el.parentNode;

  wrapper.querySelector(".input-number-step-up").addEventListener("click", () => {
    const current = parseCurrent();
    if (!isNaN(current) && (!(max) || current < max)) {
      el.value = current + 1;
    }
  });

  wrapper.querySelector(".input-number-step-down").addEventListener("click", () => {
    const current = parseCurrent();
    if (!isNaN(current) && (!(min) || current > min)) {
      el.value = current - 1;
    }
  });
}

const standardizeSelectChevron = (el) => {
  el.wrap("<div class='ctrl-wrapper'></div>");
  const tmp = document.createElement("div");
  tmp.innerHTML = "<i class='fas fa-chevron-down'></i>";
  el.parentNode.appendChild(tmp.firstChild);
}

const standardizeDateCalendars = (el) => {
  el.wrap("<div class='date-wrapper'></div>");
  const tmp = document.createElement("div");
  tmp.innerHTML = "<i class='fas fa-calendar-alt'></i>";
  el.parentNode.appendChild(tmp.firstChild);
}

const addClearButton = (el) => {
  el.wrap("<div class='ctrl-wrapper'></div>")

  const tmp = document.createElement("div");
  tmp.innerHTML = "<div class='clear-text'><i class='fas fa-times-circle'></i></div>";
  const clear = tmp.firstChild;
  el.parentNode.appendChild(clear)

  clear.addEventListener("click", () => {
    el.value = "";
    el.dispatchEvent(new Event("input", {bubbles: true, cancelable: true}));
  });

  el.addEventListener("input", e => {
    if (e.target.value !== "") {
      clear.style.display = "flex";
    } else {
      clear.style.display = "none";
    }
  });

  if (el.value !== "") {
    clear.style.display = "flex";
  } else {
    clear.style.display = "none";
  }
}

const wrapFilePicker = (el) => {
  let busy = false;

  el.wrap("<div class='fp-wrapper'></div>")
  el.style.display = "none";

  let tmp = document.createElement("div");
  tmp.innerHTML =
    `<input type='text' class='${el.getAttribute("class")}' readonly placeholder='seleziona un file' />`;
  el.parentNode.appendChild(tmp.firstChild);

  tmp = document.createElement("div");
  tmp.innerHTML = `<a class='fp-open-browser'><i class='fad fa-folder-open'></i></a>`;
  el.parentNode.appendChild(tmp.firstChild);

  el.addEventListener("change", e => {
    const txtBox = el.parentNode.querySelector("input[type='text']");
    txtBox.value = e.target.files[0].name;
    txtBox.dispatchEvent(new Event("input", {bubbles: true, cancelable: true}));

    const submitButton = el.parentNode.parentNode.querySelector("a.fp-submit");
    if (e.target.value) {
      submitButton.removeAttribute("disabled");
    } else {
      submitButton.setAttribute("disabled", "disabled");
    }
  });

  el.parentNode.addEventListener("click", e => {
    if (!e.target.matches(".fp-open-browser, i.fad")) return;
    if (!busy) {
      busy = true;
      el.click();
      busy = false;
    }
  });
}

const disableLinkWhenTextBoxIsShorterThan = (linkSelector, txtSelector, minLength) => {
  const link = document.querySelector(linkSelector);
  const onChange = val => {
    if (val.trim().length < minLength) {
      link.setAttribute("disabled", "disabled");
    } else {
      link.removeAttribute("disabled");
    }
  };
  const txtBox = document.querySelector(txtSelector);
  txtBox.addEventListener("input", e => onChange(e.target.value));
  onChange(txtBox.value);
};

window.onpageshow = () => stopLoading();

const softLoad = () => {
  const loader = document.getElementById("loader");
  if (!loader) return;
  loader.style.display = "flex";
  setTimeout(() => loader.style.opacity = "1", 0);
};

const stopLoading = () => {
  const loader = document.getElementById("loader");
  if (!loader) return;
  loader.style.opacity = "0";
  loader.style.display = "none";
}

const swapSwampMes = () => {
  [...document.querySelectorAll(".swapme.guest")].forEach(el => {
    const swapId = el.getAttribute("swapid");
    const host = document.querySelector(".swapme.host[swapid='" + swapId + "']")
    host.parentNode.insertBefore(el, host);
    el.classList.remove("swapme", "guest");
    host.remove();
  });
};

const prepareToolsMenus = () => {
  let isAnyOpen = false;
  [...document.getElementsByClassName("tools-menu")].forEach(menu => {
    const tmp = document.createElement("div");
    tmp.innerHTML = "<a class='tools-menu-button d-block'><i class='far fa-ellipsis-h'></i></a>";
    const btn = tmp.firstChild;
    menu.parentNode.insertBefore(btn, menu);

    let created = false;
    let popper;

    btn.addEventListener("click", (e) => {
      if (isAnyOpen) return;
      e.stopPropagation();
      menu.classList.add("opened");
      isAnyOpen = true;
      if (popper) return;
      popper = Popper.createPopper(btn, menu, {
        placement: 'left-start',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-12, 16]
            }
          }
        ]
      });
      created = true;
    });

    document.addEventListener('click', () => {
      menu.classList.remove("opened");
      isAnyOpen = false;
      popper?.destroy();
      popper = null;
    });
  });
};

const makeNumberInputsNumericOnly = () => {
  const allowedChars = "0123456789.,-";
  document.addEventListener("keypress", e => {
    if (!e.target.matches("input[type='number']")) return;
    const invalidKey = e.key.length === 1
      && !(allowedChars.indexOf(e.key) > -1)
      || e.key === '.'
      && e.target.value.indexOf('.') > -1;
    invalidKey && e.preventDefault();
  });
}

const polyfillDateInputs = () => {
  if (Modernizr.inputtypes.date) return;
  console.log("Tag input di tipo 'date' non disponibili, procedo con un polyfill. AGGIORNA IL TUO BROWSER.");
  loadScript("/Content/Scripts/Libs/litepicker.js").then(() => {
    [...document.querySelectorAll("input[type='date']")].forEach(el => new Litepicker({element: el}));
  });
}

const loadScript = (scriptUrl) => {
  const script = document.createElement('script');
  script.src = scriptUrl;
  document.body.appendChild(script);
  return onLoadOnErrorPromise(script);
}

/*const loadStyle = (url) => {
  const ss = document.createElement('link');
  ss.rel = "stylesheet"
  ss.href = url;
  document.body.appendChild(ss);
  return onLoadOnErrorPromise(ss);
}*/

const onLoadOnErrorPromise = (subj) => new Promise((res, rej) => {
  subj.onload = () => {
    res();
  }
  subj.onerror = () => {
    rej();
  }
});

let loaded = false;

const initAll = () => {
  if (loaded) return;
  loaded = true;
  swapSwampMes();
  // prepareToolsMenus();

  [...document.querySelectorAll("input[type='password']:not(.no-toggle)")].forEach(addPasswordToggle);
  [...document.querySelectorAll("input[type='text']:not(.no-clear)")].forEach(addClearButton);
  [...document.querySelectorAll("input[type='email']:not(.no-clear)")].forEach(addClearButton);
  [...document.querySelectorAll("input[type='url']:not(.no-clear)")].forEach(addClearButton);
  [...document.querySelectorAll("input[type='file']:not(.no-wrap)")].forEach(wrapFilePicker);
  [...document.querySelectorAll("select:not(.no-wrap)")].forEach(standardizeSelectChevron);
  [...document.querySelectorAll("input[type='date']:not(.no-wrap)")].forEach(standardizeDateCalendars);
  [...document.querySelectorAll("input[type='number']:not(.short)")].forEach(wrapNumberInput);

  polyfillDateInputs();
  makeNumberInputsNumericOnly();
};

doWhenLoaded(initAll);